// Customer Add index
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { Row, Col, Input, Button, Form, Drawer, Radio, Select, Divider, InputNumber } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';
import { API_STATUS, DATE_FORMAT, GENDER } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { formQueryStringFromObject } from 'helpers';
import AddCoach from 'pages/coaches/add-coach';
import FileUpload from 'components/file-upload/file-upload';
import DatePicker from 'components/date-picker';
import { sendGetRequest } from 'redux/sagas/utils';

const AddCustomer = ({ customerAddModal, width = '40%', editCustomer, setCustomerAddModal, refreshList, handleClose, isVisitor = false }) => {
	const [form] = Form.useForm();
	const [coachAddModal, setCoachAddModal] = useState(false);
	const [customerImage, setCustomerImage] = useState([]);
	const [allCustomers, setAllCustomers] = useState({
		data: [],
		loading: true,
	});
	const memberships = useSelector((state) => state.membershipRedux?.memberships || []);
	// const services = useSelector((state) => state?.serviceRedux?.services || []);
	const coaches = useSelector((state) => state.coachRedux?.coaches);
	const globalRedux = useSelector((state) => state.globalRedux);
	const branchId = globalRedux?.selectedBranch?.id;
	const serviceIdValue = Form.useWatch('serviceId', form);
	const joiningDateValue = Form.useWatch('joiningDate', form);
	const dobValue = Form.useWatch('dob', form);
	const membershipIdValue = Form.useWatch('membershipId', form);
	const dispatch = useDispatch();
	const services = useSelector((state) => state?.serviceRedux?.services || []);
	const serviceLoading = useMemo(() => globalRedux?.apiStatus.GET_SERVICES === API_STATUS.PENDING, [globalRedux?.apiStatus?.GET_SERVICES]);
	const membershipLoading = useMemo(() => globalRedux?.apiStatus.GET_MEMBERSHIPS === API_STATUS.PENDING, [globalRedux?.apiStatus?.GET_MEMBERSHIPS]);
	const PAYMENT_METHOD = [
		{ label: 'Cash', value: 'cash' },
		{ label: 'UPI', value: 'upi' },
	];
	const paymentMethodValue = Form.useWatch('paymentMethod', form);
	const amountValue = Form.useWatch('amount', form);
	const [originalAmount, setOriginalAmount] = useState(0);

	useEffect(() => {
		if (dobValue) {
			let age = moment().diff(dobValue, 'years');
			form.setFieldsValue({
				age,
			});
		}
	}, [dobValue, form]);

	const string = useMemo(() => {
		const string = formQueryStringFromObject({
			branchId,
		});
		return string;
	}, [branchId]);

	const getMemberships = useCallback(
		(serviceId) => {
			const query = formQueryStringFromObject({
				serviceId,
			});
			let membershipUrl = `${SERVER_IP}api/memberships?${string}&${query}`;
			dispatch(getApi('GET_MEMBERSHIPS', membershipUrl));
		},
		[dispatch, string]
	);

	useEffect(() => {
		let balance = originalAmount - (form.getFieldValue('amount') || 0);
		form.setFieldsValue({
			balance: customerAddModal ? balance : 0,
		});
	}, [amountValue, form, originalAmount, customerAddModal]);

	useEffect(() => {
		if (membershipIdValue) {
			const membership = memberships?.find((membership) => membership?.id === membershipIdValue);
			const amount = membership?.offerPrice || membership?.originalPrice;
			setOriginalAmount(amount);
			form.setFieldsValue({
				amount,
				paidOn: joiningDateValue || moment(),
				nextBillDate: moment(joiningDateValue).add(membership?.days, 'days'),
			});
		}
	}, [membershipIdValue, form, memberships, joiningDateValue]);

	const getServices = useCallback(() => {
		let serviceUrl = `${SERVER_IP}api/services?${string}`;
		dispatch(getApi('GET_SERVICES', serviceUrl));
	}, [dispatch, string]);

	const getCustomers = useCallback(async () => {
		setAllCustomers((allCustomers) => ({
			...allCustomers,
			loading: true,
		}));
		const { data } = await sendGetRequest(null, `${SERVER_IP}api/customers/getCustomerSB?${string}`);
		console.log('🚀 ~ getCustomers ~ data:', data?.data);
		setAllCustomers({
			data: data?.data || [],
			loading: false,
		});
	}, [string]);

	const getCoaches = useCallback(() => {
		const string = formQueryStringFromObject({
			branchId,
		});
		let url = `${SERVER_IP}api/coaches?${string}`;
		customerAddModal && dispatch(getApi('GET_COACHES', url));
	}, [dispatch, branchId, customerAddModal]);

	useEffect(() => {
		if (serviceIdValue) {
			form.setFieldsValue({
				membershipId: '',
				amount: '',
			});
			getMemberships(serviceIdValue);
		}
	}, [serviceIdValue, getMemberships, form]);

	useEffect(() => {
		getCustomers();
		getCoaches();
		getServices();
	}, [getCoaches, getServices, getCustomers]);

	useEffect(() => {
		setCustomerImage([]);
		const customerImage = JSON.parse(JSON.stringify(editCustomer));
		if (!isVisitor && customerImage) {
			if (customerImage.image && customerImage.image.length && customerImage?.image[0]?.url) {
				customerImage.image[0] = typeof customerImage?.image[0] === 'string' ? JSON.parse(customerImage?.image[0]) : customerImage?.image[0];
				customerImage.image[0].url = String(customerImage?.image[0]?.url).includes(SERVER_IP)
					? customerImage?.image[0]?.url
					: SERVER_IP + customerImage?.image[0]?.url;
			} else {
				customerImage.image = [];
			}

			form.setFieldsValue({
				customerName: editCustomer?.customerName || '',
				age: editCustomer?.age || '',
				dob: editCustomer?.dob ? moment(editCustomer?.dob) : moment().subtract(18, 'years'),
				nextBillDate: editCustomer?.nextBillDate ? moment(editCustomer?.nextBillDate) : moment(),
				email: editCustomer?.email || '',
				mobile: editCustomer?.mobile || '',
				weight: editCustomer?.weight || '',
				height: editCustomer?.height || '',
				occupation: editCustomer?.occupation || '',
				address: editCustomer?.address || '',
				gender: editCustomer?.gender || '',
				joiningDate: moment(editCustomer?.joiningDate) || '',
				referredBy: editCustomer?.referredBy || '',
				onBoardedBy: editCustomer?.onBoardedBy || '',
				aadhaarNo: editCustomer?.aadhaarNo || '',
				visitorId: editCustomer?.visitorId || '',
				image: customerImage.image || [],
			});
			if (customerImage?.image?.length) {
				setCustomerImage(customerImage?.image);
			}
		} else {
			form?.resetFields();
			if (isVisitor) {
				form.setFieldsValue({
					customerName: editCustomer?.customerName || '',
					mobile: editCustomer?.mobile || '',
					visitorId: editCustomer?.visitorId || '',
				});
			}
		}
	}, [editCustomer, form, isVisitor]);

	const handleSubmit = (values) => {
		const customerPic = {
			uid: customerImage[0]?.uid,
			url: customerImage[0]?.url?.replace(SERVER_IP, '') || '',
			name: customerImage[0]?.name,
			thumbUrl: customerImage[0]?.thumbUrl,
		};

		customerImage[0] = customerPic;

		let data = {
			customerName: values?.customerName || '',
			age: values?.age || '',
			dob: values?.dob || '',
			weight: values?.weight || '',
			height: values?.height || '',
			occupation: values?.occupation || '',
			email: values?.email || '',
			mobile: values?.mobile || '',
			aadhaarNo: values?.aadhaarNo || '',
			address: values?.address || '',
			gender: values?.gender || '',
			joiningDate: values?.joiningDate || '',
			referredBy: values?.referredBy || '',
			onBoardedBy: values?.onBoardedBy || '',
			image: customerImage,
			branchId,

			...(!editCustomer && {
				serviceId: values?.serviceId,
				membershipId: values?.membershipId,
				amount: values?.amount,
				balance: values?.balance,
				role: 'customer',
				paymentMethod: values?.paymentMethod || 'cash',
				paidOn: values?.paidOn || '',
				nextBillDate: values?.nextBillDate || '',
				remarks: values?.remarks || '',
				upiTransactionId: values?.upiTransactionId || '',
				image: customerImage,
				visitorId: values?.visitorId || null,
			}),
		};

		if (!editCustomer || isVisitor) {
			dispatch(postApi(data, 'ADD_CUSTOMER'));
		} else {
			let url = `${SERVER_IP}api/customers/${editCustomer.id}`;
			dispatch(putApi(data, 'EDIT_CUSTOMER', url));
		}
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_CUSTOMER === 'SUCCESS' || globalRedux.apiStatus.EDIT_CUSTOMER === 'SUCCESS') {
			handleClose?.();
			refreshList?.(true);
			dispatch(resetApiStatus(editCustomer ? 'EDIT_CUSTOMER' : 'ADD_CUSTOMER'));
			form?.resetFields();
		}
	}, [globalRedux.apiStatus, editCustomer, setCustomerAddModal, dispatch, refreshList, handleClose, form]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading = globalRedux.apiStatus.ADD_CUSTOMER === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_CUSTOMER === API_STATUS.PENDING;
	// const membershipLoading = useMemo(() => globalRedux?.apiStatus.GET_MEMBERSHIPS === API_STATUS.PENDING, [globalRedux?.apiStatus?.GET_MEMBERSHIPS]);
	// const serviceLoading = useMemo(() => globalRedux?.apiStatus.GET_SERVICES === API_STATUS.PENDING, [globalRedux?.apiStatus?.GET_SERVICES]);

	return (
		<>
			<Drawer
				placement="right"
				title={`${editCustomer ? 'Edit' : 'New'} Customer`}
				width={width || '40%'}
				open={customerAddModal}
				closable
				onClose={() => setCustomerAddModal(false)}
				destroyOnClose={true}>
				<Row>
					<Col span={24}>
						<Form
							name="add-customer"
							className="required_in_right"
							style={{ width: '100%' }}
							colon={true}
							labelAlign="left"
							form={form}
							onFinish={handleSubmit}
							initialValues={{}}
							{...layer1FormCol}>
							<Form.Item
								label="Customer Name"
								name="customerName"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<Input placeholder="Customer name" />
							</Form.Item>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								initialValue={moment().subtract(18, 'years')}
								label="Date of Birth"
								name="dob">
								<DatePicker style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
							</Form.Item>
							<Form.Item
								label="Age"
								name="age"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<Input placeholder="Age" />
							</Form.Item>
							<Form.Item
								label="Gender"
								name="gender"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<Radio.Group options={GENDER} optionType="button" buttonStyle="solid" />
							</Form.Item>
							<Form.Item
								label="Mobile"
								name="mobile"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<Input maxLength={10} placeholder="Mobile" />
							</Form.Item>
							<Form.Item label="Email" name="email">
								<Input placeholder="Email" />
							</Form.Item>
							<Form.Item
								rules={[
									{
										required: false,
										message: 'This Field is required!',
									},
								]}
								label="Aadhaar"
								name="aadhaarNo">
								<Input placeholder="Aadhaar" />
							</Form.Item>
							<Form.Item
								label="Address"
								name="address"
								rules={[
									{
										required: false,
										message: 'This Field is required!',
									},
								]}>
								<Input.TextArea placeholder="Address" />
							</Form.Item>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								initialValue={moment()}
								label="Joining Date"
								name="joiningDate">
								<DatePicker style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
							</Form.Item>
							{/* <Form.Item
								rules={[
									{
										required: false,
										message: 'This Field is required!',
									},
								]}
								label="Service type"
								name="serviceId">
								<Select loading={serviceLoading} placeholder="Service type">
									{services?.map((service, index) => (
										<Select.Option key={index} value={service._id}>
											{service?.serviceName}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								rules={[
									{
										required: false,
										message: 'This Field is required!',
									},
								]}
								label="Membership"
								name="membershipId">
								<Select disabled={!serviceIdValue} loading={membershipLoading} placeholder="Membership">
									{memberships?.map((membership, index) => (
										<Select.Option key={index} value={membership._id}>
											{membership?.membershipName}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								label="Amount"
								name="amount"
								rules={[
									{
										required: false,
										message: 'This Field is required!',
									},
								]}>
								<InputNumber placeholder="Amount" style={{ width: '100%' }} />
							</Form.Item> */}
							<Form.Item label="Referred By" name="referredBy">
								<Select
									placeholder="Referred By"
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) => {
										return option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
									}}>
									{(allCustomers?.data || [])?.map((customer) => (
										<Select.Option value={customer.id}>{`${customer?.customerName} - ${customer?.mobile}`}</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								label="On-Boarded By"
								name="onBoardedBy">
								<Select
									placeholder="On-Boarded By"
									dropdownRender={(menu) => (
										<>
											{menu}
											<Divider />
											<div style={{}}>
												<a
													href
													style={{ color: '#188dfa', padding: 10, cursor: 'pointer' }}
													onClick={() => setCoachAddModal(true)}>
													<PlusOutlined /> Coach
												</a>
											</div>
										</>
									)}>
									{coaches?.map((coach) => (
										<Select.Option key={coach.id} value={coach.id}>
											{coach?.coachName}
										</Select.Option>
									))}
								</Select>
							</Form.Item>

							<Row>
								<hr />
								<Col span={24} style={{ fontWeight: 'bold' }}>
									Personal Details
								</Col>
								<hr />
							</Row>
							<Form.Item
								label="Height (in CM)"
								name="height"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<InputNumber placeholder="Height (in CM)" style={{ width: '100%' }} />
							</Form.Item>
							<Form.Item
								label="Weight (in Kgs)"
								name="weight"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<InputNumber placeholder="Weight (in Kgs)" style={{ width: '100%' }} />
							</Form.Item>
							<Form.Item
								label="Occupation"
								name="occupation"
								rules={[
									{
										required: false,
										message: 'This Field is required!',
									},
								]}>
								<Input placeholder="Occupation" style={{ width: '100%' }} />
							</Form.Item>
							{!editCustomer || isVisitor ? (
								<>
									<Row>
										<hr />
										<Col span={24} style={{ fontWeight: 'bold' }}>
											Package Details
										</Col>
										<hr />
									</Row>
									<Form.Item
										rules={[
											{
												required: true,
												message: 'This Field is required!',
											},
										]}
										label="Service type"
										name="serviceId">
										<Select loading={serviceLoading} placeholder="Service type">
											{services?.map((service, index) => (
												<Select.Option key={index} value={service.id}>
													{service?.serviceName}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
									<Form.Item
										rules={[
											{
												required: true,
												message: 'This Field is required!',
											},
										]}
										label="Membership"
										name="membershipId">
										<Select loading={membershipLoading} placeholder="Membership" disabled={!Boolean(serviceIdValue)}>
											{memberships?.map((membership, index) => (
												<Select.Option key={index} value={membership.id}>
													{membership?.membershipName} - {membership?.offerPrice || membership?.originalPrice}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
									<Form.Item
										rules={[
											{
												required: true,
												message: 'This Field is required!',
											},
										]}
										label="Amount"
										name="amount">
										<InputNumber placeholder="Amount" style={{ width: '100%' }} />
									</Form.Item>
									<Form.Item label="Balance" name="balance">
										<InputNumber placeholder="Balance" disabled style={{ width: '100%' }} />
									</Form.Item>
									<Form.Item
										rules={[
											{
												required: true,
												message: 'This Field is required!',
											},
										]}
										label="Paid on"
										name="paidOn">
										<DatePicker style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
									</Form.Item>
									<Form.Item
										rules={[
											{
												required: true,
												message: 'This Field is required!',
											},
										]}
										label="Next Bill Date"
										name="nextBillDate">
										<DatePicker style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
									</Form.Item>
									<Form.Item
										rules={[
											{
												required: true,
												message: 'This Field is required!',
											},
										]}
										label="Payment Method"
										name="paymentMethod">
										<Radio.Group style={{ width: '100%' }} options={PAYMENT_METHOD} optionType="button" buttonStyle="solid" />
									</Form.Item>
									{paymentMethodValue === PAYMENT_METHOD[1]?.value && (
										<Form.Item
											label="UPI TransactionId"
											name="upiTransactionId"
											rules={[
												{
													required: true,
													message: 'This Field is required!',
												},
											]}>
											<InputNumber placeholder="UPI TransactionId" style={{ width: '100%' }} />
										</Form.Item>
									)}
									<Form.Item
										label="Remarks"
										name="remarks"
										rules={[
											{
												required: false,
												message: 'This Field is required!',
											},
										]}>
										<Input.TextArea />
									</Form.Item>
								</>
							) : null}
							<FileUpload
								action={'/'}
								afterUpload={() => {}}
								max={1}
								fileList={form.getFieldValue('image')}
								setFileList={setCustomerImage}
							/>
							<Form.Item
								wrapperCol={{
									offset: 0,
									span: 24,
								}}>
								<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
									<Button danger style={{ width: '49%' }} onClick={() => setCustomerAddModal(false)}>
										Cancel
									</Button>
									<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
										{editCustomer ? 'Update' : 'Save'}
									</Button>
								</Row>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Drawer>
			<AddCoach {...{ coachAddModal, setCoachAddModal, refreshList: getCoaches, handleClose: () => setCoachAddModal(false) }} />
		</>
	);
};

export default AddCustomer;
