import React, { useCallback, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { SERVER_IP } from 'assets/Config';
import { ACTIONS, API_STATUS } from 'constants/app-constants';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { formQueryStringFromObject } from 'helpers';
import DashboardPresentational from './dashboard-presentational';
import { getStartDateOfMonth } from 'services/Utils';
import dayjs from 'dayjs';

const DashboardFunctional = () => {
	const loginRedux = useSelector((state) => state.loginRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const dashboardData = useSelector(({ dashboardRedux }) => dashboardRedux?.dashboard);
	const payments = useSelector((state) => state.paymentRedux?.payments);
	const branches = useSelector(({ globalRedux: { branches } }) => branches);
	const branchId = globalRedux?.selectedBranch?.id;

	const filterOptions = React.useMemo(
		() => ({
			fromDate: moment().subtract(6, 'months').toISOString(),
			toDate: moment().toISOString(),
		}),
		[]
	);

	const dispatch = useDispatch();
	const loading = React.useMemo(() => globalRedux.apiStatus.GET_DASHBOARD_DATA === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const getDashboardData = useCallback(
		(month) => {
			console.log({ month: new Date(month), startDateofMonth: getStartDateOfMonth(dayjs(month)) });
			const string = formQueryStringFromObject({
				branchId,
				month,
				...filterOptions,
			});
			let url = `${SERVER_IP}api/dashboard?${string}`;
			dispatch(getApi(ACTIONS.GET_DASHBOARD_DATA, url));
		},
		[dispatch, branchId, filterOptions]
	);

	const getPayments = useCallback(() => {
		const string = formQueryStringFromObject({
			branchId,
			type: 'all',
		});
		let url = `${SERVER_IP}api/payments?limit=10&${string}`;
		dispatch(getApi('GET_PAYMENTS', url));
	}, [dispatch, branchId]);

	useEffect(() => {
		getDashboardData();
		getPayments();
	}, [getDashboardData, getPayments]);

	return <DashboardPresentational {...{ dashboardData, getDashboardData, loginRedux, branches, payments, loading }} />;
};

export default DashboardFunctional;
