import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Row, Col, Input, Button, Form, Drawer, Radio, DatePicker, Select, InputNumber, Divider } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import AddProduct from 'pages/products/add-product';
import { API_STATUS, DATE_FORMAT, PAYMENT_METHOD } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { formQueryStringFromObject } from 'helpers';
import { getApi } from 'redux/sagas/getApiDataSaga';
// import { sendGetRequest } from 'redux/sagas/utils';
import PaymentCustomer from './payment-customer';

const AddPayment = ({ paymentAddModal, width = '40%', editPayment, setPaymentAddModal, refreshList, handleClose }) => {
	const [form] = Form.useForm();
	const [productAddModal, setProductAddModal] = useState(false);
	const globalRedux = useSelector((state) => state.globalRedux);
	const products = useSelector((state) => state.productRedux?.products || []);
	const memberships = useSelector((state) => state.membershipRedux?.memberships || []);
	const services = useSelector((state) => state?.serviceRedux?.services || []);
	const coaches = useSelector((state) => state.coachRedux?.coaches);
	const paymentFor = useSelector((state) => state?.paymentRedux?.paymentFor);
	const paymentMethodValue = Form.useWatch('paymentMethod', form);
	const serviceIdValue = Form.useWatch('serviceId', form);
	const membershipIdValue = Form.useWatch('membershipId', form);
	const productIdValue = Form.useWatch('productId', form);
	const paidOnValue = Form.useWatch('paidOn', form);
	const qtyValue = Form.useWatch('qty', form);
	const amountValue = Form.useWatch('amount', form);
	// const [allCustomers, setAllCustomers] = useState({
	// 	data: [],
	// 	loading: true,
	// });
	const dispatch = useDispatch();
	const branchId = globalRedux?.selectedBranch?.id;

	const string = useMemo(() => {
		const string = formQueryStringFromObject({
			branchId,
		});
		return string;
	}, [branchId]);

	const getMemberships = useCallback(
		(serviceId) => {
			const query = formQueryStringFromObject({
				serviceId,
			});
			let membershipUrl = `${SERVER_IP}api/memberships?${string}&${query}`;
			dispatch(getApi('GET_MEMBERSHIPS', membershipUrl));
		},
		[dispatch, string]
	);

	const getServices = useCallback(() => {
		let serviceUrl = `${SERVER_IP}api/services?${string}`;
		dispatch(getApi('GET_SERVICES', serviceUrl));
	}, [dispatch, string]);

	const getCoaches = useCallback(() => {
		let coachUrl = `${SERVER_IP}api/coaches?${string}`;
		dispatch(getApi('GET_COACHES', coachUrl));
	}, [dispatch, string]);

	const getProducts = useCallback(() => {
		let productsUrl = `${SERVER_IP}api/products?${string}`;
		dispatch(getApi('GET_PRODUCTS', productsUrl));
	}, [dispatch, string]);

	useEffect(() => {
		if (paymentAddModal && paymentFor === 'membership') {
			getServices();
		} else form?.resetFields();
	}, [getServices, paymentAddModal, form, paymentFor]);

	useEffect(() => {
		getCoaches();
	}, [getCoaches]);

	useEffect(() => {
		if (paymentAddModal && paymentFor === 'product') {
			getProducts();
		} else form?.resetFields();
	}, [getProducts, paymentAddModal, form, paymentFor]);

	// const getCustomers = useCallback(async () => {
	// 	setAllCustomers((allCustomers) => ({
	// 		...allCustomers,
	// 		loading: true,
	// 	}));
	// 	const { data: { data } = {} } = await sendGetRequest(null, `${SERVER_IP}api/customers?${string}`);
	// 	setAllCustomers({
	// 		data,
	// 		loading: false,
	// 	});
	// 	// eslint-disable-next-line
	// }, [globalRedux?.selectedBranch?.id]);

	// useEffect(() => {
	// 	getCustomers();
	// }, [getCustomers]);

	// useEffect(() => {
	// 	if (paymentAddModal) {
	// 		getCustomers();
	// 	}
	// }, [paymentAddModal, getCustomers]);

	useEffect(() => {
		if (serviceIdValue) {
			form.setFieldsValue({
				membershipId: '',
				amount: '',
			});
			getMemberships(serviceIdValue);
		}
	}, [serviceIdValue, getMemberships, form]);

	const calculateBalance = useCallback(() => {
		const amountFormValue = form.getFieldValue('amount');
		const qtyValue = form.getFieldValue('qty');
		let actualAmount = 0;
		if (paymentFor === 'product') {
			const product = products?.find((product) => product?.id === productIdValue);
			actualAmount = product?.offerPrice || product?.originalPrice;
		} else {
			const membership = memberships?.find((membership) => membership?.id === membershipIdValue);
			actualAmount = membership?.offerPrice || membership?.originalPrice;
		}
		const qty = qtyValue ? qtyValue : 1;
		const balance = actualAmount * qty - amountFormValue;
		console.log({ balance, actualAmount, qty, amountFormValue });
		form.setFieldValue('balance', balance);
	}, [form, membershipIdValue, memberships, paymentFor, productIdValue, products]);

	useEffect(() => {
		if (amountValue) {
			calculateBalance();
		}
	}, [amountValue, calculateBalance]);

	useEffect(() => {
		if (membershipIdValue) {
			const amountFormValue = form.getFieldValue('amount');
			const membership = memberships?.find((membership) => membership?.id === membershipIdValue);
			const amount = amountFormValue ? amountFormValue : membership?.offerPrice || membership?.originalPrice;
			form.setFieldsValue({
				amount,
				...(paidOnValue && { nextBillDate: moment(paidOnValue).add(membership?.days, 'days') }),
			});
			calculateBalance();
		}
	}, [membershipIdValue, form, memberships, paidOnValue, calculateBalance]);

	useEffect(() => {
		const qty = qtyValue ? qtyValue : 1;
		if (productIdValue) {
			const product = products?.find((product) => product?.id === productIdValue);
			const amount = product?.offerPrice || product?.originalPrice;
			form.setFieldsValue({
				amount: amount * qty,
			});
			calculateBalance();
		}
	}, [qtyValue, form, productIdValue, products, calculateBalance]);

	useEffect(() => {
		if (productIdValue) {
			const amountFormValue = form.getFieldValue('amount');
			const product = products?.find((product) => product?.id === productIdValue);
			const amount = amountFormValue ? amountFormValue : product?.offerPrice || product?.originalPrice;
			form.setFieldsValue({
				qty: 1,
				amount,
				paidOn: moment(),
			});

			calculateBalance();
		}
	}, [productIdValue, form, products, calculateBalance]);

	useEffect(() => {
		form?.resetFields();
		form?.setFieldsValue({
			paymentFor,
		});
	}, [form, paymentFor]);

	const handleSubmit = (values) => {
		let data = {
			...values,
			customerId: values?.customerId,
			membershipId: values?.membershipId || null,
			serviceId: values?.serviceId || null,
			productId: values?.productId || null,
			qty: values?.qty || 1,
			amount: values?.amount || 0,
			balance: values?.balance || 0,
			nextBillDate: values?.nextBillDate || moment(),
			paidOn: values?.paidOn || '',
			paymentMethod: values?.paymentMethod || '',
			remarks: values?.remarks || '',
			receivedBy: values?.receivedBy || '',
			paymentFor,
			...(paymentMethodValue === PAYMENT_METHOD[1]?.value && { upiTransactionId: values?.upiTransactionId || '' }),
			branchId,
		};
		dispatch(postApi(data, 'ADD_PAYMENT'));

		// if (!editPayment) {
		// 	dispatch(postApi(data, 'ADD_PAYMENT'));
		// } else {
		// 	let url = `${SERVER_IP}api/customers/${editPayment.id}`;
		// 	dispatch(putApi(data, 'EDIT_CUSTOMER', url));
		// }
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_PAYMENT === 'SUCCESS' || globalRedux.apiStatus.EDIT_CUSTOMER === 'SUCCESS') {
			dispatch(resetApiStatus('ADD_PAYMENT'));
			// dispatch(resetApiStatus(editPayment ? 'EDIT_CUSTOMER' : 'ADD_PAYMENT'));
			refreshList?.(paymentFor);
			handleClose?.();
			form?.resetFields();
		}
	}, [globalRedux.apiStatus, editPayment, setPaymentAddModal, dispatch, refreshList, handleClose, form, paymentFor]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading = globalRedux.apiStatus.ADD_PAYMENT === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_CUSTOMER === API_STATUS.PENDING;
	const membershipLoading = useMemo(() => globalRedux?.apiStatus.GET_MEMBERSHIPS === API_STATUS.PENDING, [globalRedux?.apiStatus?.GET_MEMBERSHIPS]);
	const serviceLoading = useMemo(() => globalRedux?.apiStatus.GET_SERVICES === API_STATUS.PENDING, [globalRedux?.apiStatus?.GET_SERVICES]);
	// const customerLoading = useMemo(() => globalRedux?.apiStatus.GET_CUSTOMER === API_STATUS.PENDING, [globalRedux?.apiStatus?.GET_CUSTOMER]);
	const productLoading = useMemo(() => globalRedux?.apiStatus.GET_PRODUCT === API_STATUS.PENDING, [globalRedux?.apiStatus?.GET_PRODUCT]);

	const onCustomerDataChange = (customer) => {
		form.setFieldsValue({
			...customer,
			customerType: customer.customerType
		});
	};

	return (
		<>
			<AddProduct {...{ productAddModal, setProductAddModal, refreshList: getProducts, handleClose: () => setProductAddModal(false) }} />
			<Drawer
				placement="right"
				title={`${editPayment ? 'Edit' : 'New'} Payment`}
				width={width || '40%'}
				open={paymentAddModal}
				closable
				onClose={() => setPaymentAddModal(false)}
				destroyOnClose={true}>
				<Row>
					<Col span={24}>
						<Row style={{ marginTop: 20 }}>
							<Form
								name="pay-now"
								className="required_in_right"
								style={{ width: '100%' }}
								colon={true}
								labelAlign="left"
								form={form}
								onFinish={handleSubmit}
								{...layer1FormCol}>
								{/* <Form.Item
									rules={[
										{
											required: true,
											message: 'This Field is required!',
										},
									]}
									label="Customer Name"
									name="customerId">
									<Select
										showSearch
										optionFilterProp="children"
										filterOption={(input, option) => {
											return option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
										}}
										loading={customerLoading}
										placeholder="Customer Name">
										{(allCustomers?.data || [])?.map((customer) => (
											<Select.Option value={customer.id}>{`${customer?.customerName} - ${customer?.mobile}`}</Select.Option>
										))}
									</Select>
								</Form.Item> */}
								<PaymentCustomer paymentFor={paymentFor} onChange={onCustomerDataChange} />
								{paymentFor === 'product' ? (
									<>
										<Form.Item
											rules={[
												{
													required: true,
													message: 'This Field is required!',
												},
											]}
											label="Product Name"
											name="productId">
											<Select
												showSearch
												optionFilterProp="children"
												filterOption={(input, option) => {
													console.log("🚀 ~ AddPayment ~ input, option:", input, option)
													return option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;
												}}
												loading={productLoading}
												placeholder="Product Name"
												dropdownRender={(menu) => (
													<>
														{menu}
														<Divider />
														<div style={{}}>
															<a
																href
																style={{ color: '#188dfa', padding: 10, cursor: 'pointer' }}
																onClick={() => setProductAddModal(true)}>
																<PlusOutlined /> Product
															</a>
														</div>
													</>
												)}>
												{products?.map((product, index) => (
													<Select.Option key={index} value={product.id}>
														{`${product?.productName} - ${product?.offerPrice}`}
													</Select.Option>
												))}
											</Select>
										</Form.Item>

										<Form.Item
											label="Qty"
											name="qty"
											rules={[
												{
													required: true,
													message: 'This Field is required!',
												},
											]}>
											<InputNumber placeholder="Qty" style={{ width: '100%' }} />
										</Form.Item>
									</>
								) : (
									<>
										<Form.Item
											rules={[
												{
													required: true,
													message: 'This Field is required!',
												},
											]}
											label="Service type"
											name="serviceId">
											<Select loading={serviceLoading} placeholder="Service type">
												{services?.map((service, index) => (
													<Select.Option key={index} value={service.id}>
														{service?.serviceName}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
										<Form.Item
											rules={[
												{
													required: true,
													message: 'This Field is required!',
												},
											]}
											label="Membership"
											name="membershipId">
											<Select loading={membershipLoading} placeholder="Membership">
												{memberships?.map((membership, index) => (
													<Select.Option key={index} value={membership.id}>
														{membership?.membershipName}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</>
								)}
								<Form.Item
									label="Amount"
									name="amount"
									rules={[
										{
											required: true,
											message: 'This Field is required!',
										},
									]}>
									<InputNumber placeholder="Amount" style={{ width: '100%' }} />
								</Form.Item>
								<Form.Item
									label="Balance"
									name="balance"
									rules={[
										{
											required: true,
											message: 'This Field is required!',
										},
									]}>
									<InputNumber disabled placeholder="Balance" style={{ width: '100%' }} />
								</Form.Item>
								<Form.Item
									rules={[
										{
											required: true,
											message: 'This Field is required!',
										},
									]}
									label="Paid on"
									name="paidOn">
									<DatePicker style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
								</Form.Item>
								{paymentFor === 'membership' && (
									<Form.Item
										rules={[
											{
												required: true,
												message: 'This Field is required!',
											},
										]}
										label="Next Bill Date"
										name="nextBillDate">
										<DatePicker style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
									</Form.Item>
								)}
								<Form.Item
									label="Payment Method"
									name="paymentMethod"
									rules={[
										{
											required: true,
											message: 'This Field is required!',
										},
									]}>
									<Radio.Group style={{ width: '100%' }} options={PAYMENT_METHOD} optionType="button" buttonStyle="solid" />
								</Form.Item>
								{paymentMethodValue === PAYMENT_METHOD[1]?.value && (
									<Form.Item
										label="UPI TransactionId"
										name="upiTransactionId"
										rules={[
											{
												required: true,
												message: 'This Field is required!',
											},
										]}>
										<InputNumber placeholder="UPI TransactionId" style={{ width: '100%' }} />
									</Form.Item>
								)}
								<Form.Item
									rules={[
										{
											required: true,
											message: 'This Field is required!',
										},
									]}
									label="Received By"
									name="receivedBy">
									<Select placeholder="Received By">
										{coaches?.map((coach) => (
											<Select.Option value={coach.id}>{coach?.coachName}</Select.Option>
										))}
									</Select>
								</Form.Item>
								<Form.Item
									label="Remarks"
									name="remarks"
									rules={[
										{
											required: false,
											message: 'This Field is required!',
										},
									]}>
									<Input.TextArea />
								</Form.Item>
								<Form.Item
									wrapperCol={{
										offset: 0,
										span: 24,
									}}>
									<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
										<Button danger style={{ width: '49%' }} onClick={() => setPaymentAddModal(false)}>
											Cancel
										</Button>
										<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
											Pay
										</Button>
									</Row>
								</Form.Item>
							</Form>
						</Row>
					</Col>
				</Row>
			</Drawer>
		</>
	);
};

export default AddPayment;
