import { Col, Divider, Form, Input, Radio, Row, Select } from 'antd';
import { SERVER_IP } from 'assets/Config';
import { API_STATUS } from 'constants/app-constants';
import { formQueryStringFromObject } from 'helpers';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { sendGetRequest } from 'redux/sagas/utils';

const options = [
    {
      label: 'Customer Base',
      value: 'customer_mas',
    },
    {
      label: 'Purchase only Customer',
      value: 'purchase_only',
    }
  ];

const PaymentCustomer = ({ paymentFor, onChange }) => {
	const globalRedux = useSelector((state) => state.globalRedux);
	const [allCustomers, setAllCustomers] = useState({
		data: [],
		loading: true,
	});
	const branchId = globalRedux?.selectedBranch?.id;
    const [ customerType, setCustomerType ] = useState('customer_mas'); 


	const string = useMemo(() => {
		const string = formQueryStringFromObject({
			branchId,
		});
		return string;
	}, [branchId]);


	const getCustomers = useCallback(async () => {
		setAllCustomers((allCustomers) => ({
			...allCustomers,
			loading: true,
		}));
		const { data: { data } = {} } = await sendGetRequest(null, `${SERVER_IP}api/customers?${string}`);
		setAllCustomers({
			data,
			loading: false,
		});
		// eslint-disable-next-line
	}, [globalRedux?.selectedBranch?.id]);

	useEffect(() => {
		getCustomers();
	}, [getCustomers]);

	const customerLoading = useMemo(() => globalRedux?.apiStatus.GET_CUSTOMER === API_STATUS.PENDING, [globalRedux?.apiStatus?.GET_CUSTOMER]);

    const onChangeCustomerType = ({ target: { value } }) => {
        setCustomerType(value);
      };

    const updateForm = (value) => {
        if(paymentFor === "membership" || customerType === "customer_mas"){
            onChange({
                customerType,
                customerId: value,
            });
        }
        else{
            onChange({
                ...{
                    customerType,
                    [value.target.name]: value?.target?.value
                }
            });
        }
    }

    const CustomerDropdown = () => {
        return (<Form.Item
                rules={[
                    {
                        required: true,
                        message: 'This Field is required!',
                    },
                ]}
                label="Customer Name"
                name="customerId">
                <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                        return option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
                    }}
                    loading={customerLoading}
                    onChange={updateForm}
                    placeholder="Customer Name">
                    {(allCustomers?.data || [])?.map((customer) => (
                        <Select.Option value={customer.id}>{`${customer?.customerName} - ${customer?.mobile}`}</Select.Option>
                    ))}
                </Select>
            </Form.Item>
        )
    }

    return (
        <Fragment>
            <Divider style={{ borderColor: '#000' }}>Customer</Divider>
            <Row>
                <Col span={24} offset={3}>
                    <Radio.Group options={options} onChange={onChangeCustomerType} value={customerType} optionType="button" buttonStyle="solid" />
                </Col>
            </Row>
            <br />

            {paymentFor === "membership" || customerType === "customer_mas" ? 
                    <CustomerDropdown />
                : 
                    <Fragment>
                        <Form.Item
                            label="Customer Name"
                            name="customerName"
                            onChange={updateForm}
                            rules={[
                                {
                                    required: true,
                                    message: 'This Field is required!',
                                },
                            ]}>
                            <Input name="customerName" placeholder="Customer name" />
                        </Form.Item>
                        <Form.Item
                            label="Mobile"
                            name="mobile"
                            onChange={updateForm}
                            rules={[
                                {
                                    required: true,
                                    message: 'This Field is required!',
                                },
                            ]}>
                            <Input name="mobile" maxLength={10} placeholder="Customer mobile" />
                        </Form.Item>
                    </Fragment>}
            <Divider style={{ borderColor: '#000' }} />
            <br />
        </Fragment>
        )
}

export default PaymentCustomer;