import React, { useCallback, useEffect, useMemo } from 'react';
import { Row, Col, Button, Form, Drawer, InputNumber, Select, Radio, Input } from 'antd';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
// import { putApi } from 'redux/sagas/putApiSaga';
import { API_STATUS, DATE_FORMAT, PAYMENT_METHOD, PERSONAL_TRAINING_PERIOD } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
// import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { formQueryStringFromObject } from 'helpers';
import { getApi } from 'redux/sagas/getApiDataSaga';
import DatePicker from 'components/date-picker';
import { putApi } from 'redux/sagas/putApiSaga';
import { GET_EDIT_PERSONAL_TRAINING_PAYMENT } from 'redux/reducers/constants';

const EditPersonalTrainingPayment = ({ paymentAddModal, setPaymentAddModal, refreshList, editPaymentId, handleClose, paymentFor, width = '40%' }) => {
	const [form] = Form.useForm();
	const globalRedux = useSelector((state) => state.globalRedux);
	const branchId = globalRedux?.selectedBranch?.id;
	const coaches = useSelector((state) => state?.coachRedux?.coaches);
	const customers = useSelector((state) => state?.customerRedux?.customers || []);
	const editPTPayment = useSelector((state) => state?.personalTrainingRedux?.editPTPayment || {});
	const paidValue = Form.useWatch('paid', form);
	const daysValue = Form.useWatch('days', form);
	const amountValue = Form.useWatch('amount', form);
	const paidOnValue = Form.useWatch('paidOn', form);
	const paymentMethodValue = Form.useWatch('paymentMethod', form);
	const dispatch = useDispatch();

	const string = useMemo(() => {
		const string = formQueryStringFromObject({
			branchId,
		});
		return string;
	}, [branchId]);

	useEffect(() => {
		// form?.resetFields();
		console.log({ editPaymentId });
		if (editPaymentId) {
			let url = `${SERVER_IP}api/personalTraining/payment/${editPaymentId}`;
			dispatch(getApi(GET_EDIT_PERSONAL_TRAINING_PAYMENT, url));
		}
	}, [dispatch, editPaymentId]);

	useEffect(() => {
		form.setFieldsValue({
			...(paidOnValue && { nextBillDate: moment(paidOnValue).add(daysValue, 'days') }),
		});
	}, [form, paidOnValue, daysValue]);

	const getCustomers = useCallback(() => {
		const string = formQueryStringFromObject({
			branchId,
		});
		let url = `${SERVER_IP}api/customers?${string}`;
		branchId && dispatch(getApi('GET_CUSTOMERS', url));
	}, [dispatch, branchId]);

	// useEffect(() => {
	// 	form.setFieldValue('personalTrainingImages', personalTrainingImages)
	// }, [personalTrainingImages, form])

	const getCoaches = useCallback(() => {
		let url = `${SERVER_IP}api/coaches?${string}`;
		dispatch(getApi('GET_COACHES', url));
	}, [dispatch, string]);

	useEffect(() => {
		getCoaches();
	}, [getCoaches]);

	useEffect(() => {
		getCustomers();
	}, [getCustomers]);

	const handleSubmit = (values) => {
		let data = {
			amount: values?.amount || 0,
			balance: values?.balance || 0,
			branchId,
			coachId: values?.coachId,
			customerId: values?.customerId,
			days: values?.days,
			id: editPaymentId,
			nextBillDate: values?.nextBillDate,
			paid: values?.paid || 0,
			paidFor: 'personal_training',
			paidOn: values?.paidOn || moment(),
			paymentMethod: values?.paymentMethod,
			personalTrainingId: values?.personalTrainingId,
			receivedBy: values?.receivedBy,
			remarks: values?.remarks,

			// ...editPTPayment,
			// ...values,
			// branchId,
			// paidFor: 'personal_training',
		};
		let url = `${SERVER_IP}api/personalTraining/payment/${editPaymentId}`;
		dispatch(putApi(data, 'EDIT_PERSONAL_TRAINING', url));
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_PERSONAL_TRAINING === 'SUCCESS' || globalRedux.apiStatus.EDIT_PERSONAL_TRAINING === 'SUCCESS') {
			dispatch(resetApiStatus(editPaymentId ? 'EDIT_PERSONAL_TRAINING' : 'ADD_PERSONAL_TRAINING'));
			refreshList?.(true);
			handleClose?.();
			form?.resetFields();
		}
	}, [globalRedux.apiStatus, editPaymentId, dispatch, refreshList, handleClose, form]);

	useEffect(() => {
		if (globalRedux.apiStatus.GET_EDIT_PERSONAL_TRAINING_PAYMENT === 'SUCCESS') {
			form?.resetFields();
			form?.setFieldsValue({
				...editPTPayment,
				paidOn: moment(editPTPayment?.paidOn),
				nextBillDate: moment(editPTPayment?.nextBillDate),
				customerId: editPTPayment?.personal_training_ma?.customerId,
				coachId: editPTPayment?.personal_training_ma?.coachId,
				days: editPTPayment?.personal_training_ma?.days,
				amount: editPTPayment?.personal_training_ma?.amount,
				paid: editPTPayment?.amount,
			});
		}
	}, [globalRedux.apiStatus, form, editPTPayment]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const onClose = () => {
		setPaymentAddModal(false);
		handleClose?.();
		form?.resetFields();
	};

	const calculateBalance = useCallback(() => {
		const amountFormValue = form.getFieldValue('paid');
		let actualAmount = form.getFieldValue('amount');

		const balance = actualAmount - amountFormValue;

		form.setFieldValue('balance', balance);
	}, [form]);

	useEffect(() => {
		if (paidValue) {
			calculateBalance();
		}
	}, [paidValue, calculateBalance]);

	useEffect(() => {
		if (amountValue) {
			calculateBalance();
		}
	}, [amountValue, calculateBalance]);

	const loading =
		globalRedux.apiStatus.ADD_PERSONAL_TRAINING === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_PERSONAL_TRAINING === API_STATUS.PENDING;
	const customerLoading = useMemo(() => globalRedux?.apiStatus.GET_CUSTOMER === API_STATUS.PENDING, [globalRedux?.apiStatus?.GET_CUSTOMER]);
	const coachLoading = useMemo(() => globalRedux?.apiStatus.GET_COACHES === API_STATUS.PENDING, [globalRedux?.apiStatus?.GET_COACHES]);

	return (
		<Drawer
			placement="right"
			title={`${editPaymentId ? 'Edit' : 'New'} Personal Training`}
			width={width || '40%'}
			open={paymentAddModal}
			closable
			onClose={onClose}
			destroyOnClose={true}>
			<Row>
				<Col span={24}>
					{/* <Row style={{ backgroundColor: '#fff', padding: '10px 0px' }}>
						<Col>
							<h6 style={{ marginBottom: '0px' }}>{editPaymentId ? 'Edit' : 'New'} PersonalTraining</h6>
						</Col>
					</Row>
					<Divider /> */}
					<Row style={{ marginTop: 20 }}>
						<Form
							name="add-personalTraining"
							className="required_in_right"
							style={{ width: '100%' }}
							colon={true}
							labelAlign="left"
							form={form}
							onFinish={handleSubmit}
							initialValues={{}}
							{...layer1FormCol}>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								label="Customer Name"
								name="customerId">
								<Select
									showSearch
									disabled
									optionFilterProp="children"
									filterOption={(input, option) => {
										return option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
									}}
									loading={customerLoading}
									placeholder="Customer Name">
									{(customers || [])?.map((customer) => (
										<Select.Option value={customer.id}>{`${customer?.customerName} - ${customer?.mobile}`}</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								label="Trainer Name"
								name="coachId">
								<Select
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) => {
										return option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
									}}
									loading={coachLoading}
									placeholder="Trainer Name">
									{(coaches || [])?.map((coach) => (
										<Select.Option value={coach.id}>{`${coach?.coachName} - ${coach?.mobile}`}</Select.Option>
									))}
								</Select>
							</Form.Item>
							<>
								<Form.Item
									rules={[
										{
											required: true,
											message: 'This Field is required!',
										},
									]}
									label="Days"
									name="days">
									<Select placeholder="Days">
										{PERSONAL_TRAINING_PERIOD?.map((period, index) => (
											<Select.Option key={index} value={period.value}>
												{`${period?.name} - (${period.value} Days)`}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
								<Form.Item
									label="Amount"
									name="amount"
									rules={[
										{
											required: true,
											message: 'This Field is required!',
										},
									]}>
									<InputNumber disabled placeholder="Amount" style={{ width: '100%' }} />
								</Form.Item>
								<Form.Item
									label="Paid"
									name="paid"
									rules={[
										{
											required: true,
											message: 'This Field is required!',
										},
									]}>
									<InputNumber placeholder="Paid" style={{ width: '100%' }} />
								</Form.Item>
								<Form.Item
									label="Balance"
									name="balance"
									rules={[
										{
											required: true,
											message: 'This Field is required!',
										},
									]}>
									<InputNumber disabled placeholder="Balance" style={{ width: '100%' }} />
								</Form.Item>
								<Form.Item
									label="Payment Method"
									name="paymentMethod"
									rules={[
										{
											required: true,
											message: 'This Field is required!',
										},
									]}>
									<Radio.Group style={{ width: '100%' }} options={PAYMENT_METHOD} optionType="button" buttonStyle="solid" />
								</Form.Item>
								{paymentMethodValue === PAYMENT_METHOD[1]?.value && (
									<Form.Item
										label="UPI TransactionId"
										name="upiTransactionId"
										rules={[
											{
												required: true,
												message: 'This Field is required!',
											},
										]}>
										<InputNumber placeholder="UPI TransactionId" style={{ width: '100%' }} />
									</Form.Item>
								)}
								<Form.Item
									rules={[
										{
											required: true,
											message: 'This Field is required!',
										},
									]}
									label="Received By"
									name="receivedBy">
									<Select loading={coachLoading} placeholder="Received By">
										{coaches?.map((coach) => (
											<Select.Option value={coach.id} key={coach.id}>
												{coach?.coachName}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
								<Form.Item
									rules={[
										{
											required: true,
											message: 'This Field is required!',
										},
									]}
									label="Paid on"
									name="paidOn">
									<DatePicker style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
								</Form.Item>
								<Form.Item
									rules={[
										{
											required: true,
											message: 'This Field is required!',
										},
									]}
									label="Next Bill Date"
									name="nextBillDate">
									<DatePicker style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
								</Form.Item>
							</>
							<Form.Item
								label="Remarks"
								name="remarks"
								rules={[
									{
										required: false,
										message: 'This Field is required!',
									},
								]}>
								<Input.TextArea />
							</Form.Item>
							<Form.Item
								wrapperCol={{
									offset: 0,
									span: 24,
								}}>
								<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
									<Button danger style={{ width: '49%' }} onClick={onClose}>
										Cancel
									</Button>
									<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
										{editPaymentId ? 'Update' : 'Save'}
									</Button>
								</Row>
							</Form.Item>
						</Form>
					</Row>
				</Col>
			</Row>
		</Drawer>
	);
};

export default EditPersonalTrainingPayment;
