import * as FA from 'react-icons/fa';
import * as MD from 'react-icons/md';
// import * as FI from 'react-icons/fi';
import * as HI from 'react-icons/hi';
import * as AI from 'react-icons/ai';
// import * as FC from 'react-icons/fc';
import * as RI from 'react-icons/ri';
import Board from 'pages/board';
import Dashboard from 'pages/dashboard';
import StudentsList from 'pages/students/students-list';
import Items from 'pages/items/Items';
import Master from 'pages/master';
import LoadIn from 'pages/load-in/load-in-list';
import VoucherList from 'pages/voucher/voucher-list';
import TicketList from 'pages/tickets/ticket-list';
import InvoiceList from 'pages/invoice/invoice-list';
import AddInvoice from 'pages/invoice/add-invoice';
import Projects from 'pages/projects';
import CustomerList from 'pages/customers/customer-list';
import UserList from 'pages/users/user-list';
import InvoiceDetail from 'pages/invoice/invoice-details';
import CoachList from 'pages/coaches/coach-list';
import MembershipList from 'pages/memberships/membership-list';
import PaymentList from 'pages/payments/payment-list';
import ProductList from 'pages/products/product-list';
import Profile from 'pages/profile';
import ReportsTab from 'pages/reports';
import ServiceList from 'pages/services/service-list';
import VisitorList from 'pages/visitors/visitor-list';
import SMSPricing from 'pages/sms/sms-pricing';
import SendBulkSMS from 'pages/sms/send-bulk-sms';
import SendSingleSMS from 'pages/sms/send-single-sms';
import CustomerDetails from 'pages/customers/customer-details';
import OutstandingList from 'pages/outstanding/outstanding-list';
import RoleManagement from 'pages/role-management';
import GymList from 'pages/gyms/gym-list';
import PersonalTrainingList from 'pages/personal-training/personal-training-list'
import GymPaymentList from 'pages/gym-payments/payment-list';

const API_STATUS = {
	PENDING: 'PENDING',
	SUCCESS: 'SUCCESS',
	ERROR: 'ERROR',
};

const ACTIONS = {
	ADD_STUDENT: 'ADD_STUDENT',
	ADD_VOUCHER: 'ADD_VOUCHER',
	ADD_VOUCHER_HEAD: 'ADD_VOUCHER_HEAD',
	EDIT_STUDENT: 'EDIT_STUDENT',
	EDIT_VOUCHER: 'EDIT_VOUCHER',
	GET_AGENTS: 'GET_AGENTS',
	GET_INVOICES: 'GET_INVOICES',
	GET_STUDENTS: 'GET_STUDENTS',
	GET_STUDENTS_FEES: 'GET_STUDENTS_FEES',
	GET_VOUCHERS: 'GET_VOUCHERS',
	GET_RECEIPTS: 'GET_RECEIPTS',
	GET_LOAD_IN: 'GET_LOAD_IN',
	GET_VOUCHERS_HEAD: 'GET_VOUCHERS_HEAD',
	GET_DASHBOARD_DATA: 'GET_DASHBOARD_DATA',
	GET_ITEMS: 'GET_ITEMS',
	GET_PROJECTS: 'GET_PROJECTS',
	GET_CLASSES: 'GET_CLASSES',
	GET_ACCOUNT_BOOKS: 'GET_ACCOUNT_BOOKS',
	GET_SALES_PERSONS: 'GET_SALES_PERSONS',
	SET_ACCOUNT_BOOKS: 'SET_ACCOUNT_BOOKS',
	SET_SALES_PERSONS: 'SET_SALES_PERSONS',
	GET_TICKETS: 'GET_TICKETS',
	GET_TICKETS_DETAILS: 'GET_TICKETS_DETAILS',
};

export const GYM_PACKAGES = [
	{
		name: 'Monthly',
		value: 'monthly',
		price: 1000,
		days: 30,
	},
	{
		name: 'Quarterly',
		value: 'quarterly',
		price: 2500,
		days: 90,
	},
	{
		name: 'Half Yearly',
		value: 'half_yearly',
		price: 5000,
		days: 180,
	},
	{
		name: 'Yearly',
		value: 'yearly',
		price: 10000,
		days: 365,
	},
];

export const PERSONAL_TRAINING_PERIOD = [
	{
		name: 'Monthly',
		value: 30,
	},
	{
		name: 'Quarterly',
		value: 90,
	},
	{
		name: 'Half Yearly',
		value: 180,
	},
	{
		name: 'Yearly',
		value: 365,
	},
];

export const DATE_FORMAT = {
	YYYY_MM_DD: 'YYYY-MM-DD',
	YYYY_MM_DD_HRS: 'YYYY-MM-DDTHH:mm:ss',
	DD_MM_YYYY: 'DD-MM-YYYY',
	MM_DD_YYYY: 'MM-DD-YYYY',
	DD_MM_YYYY_HR: 'DD-MM-YYYY HH:mm',
	DD_MM_YYYY_HRS: 'DD-MM-YYYY HH:mm:ss',
	MM_YYYY: 'MM-YYYY',
};

const MENUS = [
	{
		name: 'Dashboard',
		icon: <MD.MdDashboard />,
		route: '/',
		key: '1',
	},
	{
		name: 'Customers',
		icon: <HI.HiOutlineUsers />,
		route: '/customers',
		key: '2',
	},
	{
		name: 'Visitors',
		icon: <FA.FaCalendarDay />,
		route: '/visitors',
		key: '3',
	},
	{
		name: 'Personal Training',
		icon: <RI.RiShoppingCart2Line />,
		route: '/personal-training',
		key: '4',
	},
	{
		name: 'Products',
		icon: <RI.RiShoppingCart2Line />,
		route: '/products',
		key: '5',
	},
	{
		name: 'Coaches',
		icon: <HI.HiOutlineUserGroup />,
		route: '/coaches',
		key: '6',
	},
	{
		name: 'Services',
		icon: <AI.AiOutlineSafety />,
		route: '/services',
		key: '7',
	},
	{
		name: 'Memberships',
		icon: <AI.AiOutlineSafety />,
		route: '/memberships',
		key: '8',
	},
	{
		name: 'Payments',
		icon: <FA.FaReceipt />,
		route: '/payments',
		key: '9',
	},
	{
		name: 'Outstanding',
		icon: <FA.FaCalendarDay />,
		route: '/outstanding',
		key: '10',
	},
	// {
	// 	name: 'Reports',
	// 	icon: <FA.FaFileInvoice />,
	// 	route: '/reports',
	// 	key: '10',
	// },
	// {
	// 	name: 'Bulk SMS',
	// 	icon: <FA.FaCalendarDay />,
	// 	route: '/sms',
	// 	key: '11',
	// 	submenus: [
	// 		{
	// 			name: 'Send Bulk SMS',
	// 			route: '/send-bulk-sms',
	// 			key: '12',
	// 		},
	// 		{
	// 			name: 'Send Single SMS',
	// 			route: '/send-single-sms',
	// 			key: '13',
	// 		},
	// 		{
	// 			name: 'SMS Pricing List',
	// 			route: '/sms-pricing',
	// 			key: '14',
	// 		},
	// 		{
	// 			name: 'SMS History',
	// 			route: '/sms-history',
	// 			key: '15',
	// 		},
	// 	],
	// },
	{
		name: 'Roles',
		icon: <FA.FaFileInvoice />,
		route: '/role-management',
		key: '11',
	},
];

const SUPER_ADMIN_MENUS = [
	{
		name: 'Dashboard',
		icon: <MD.MdDashboard />,
		route: '/',
		key: '1',
	},
	{
		name: 'Gyms',
		icon: <HI.HiOutlineUsers />,
		route: '/gyms',
		key: '2',
	},
	{
		name: 'Gym Payments',
		icon: <FA.FaReceipt />,
		route: '/gym-payments',
		key: '3',
	},
];

export const ROUTE_CONSTANTS = {
	NEW_INVOICE: '/invoice/new',
	INVOICE_DETAILS: '/invoice',
};

const ROUTES = [
	{
		route: '/invoice/:id',
		Component: InvoiceDetail,
	},
	{
		route: '/ticket/:selectedTicketId',
		Component: TicketList,
	},
	{
		route: '/customer/:id',
		Component: CustomerDetails,
	},
	{
		route: '/invoice/new',
		Component: AddInvoice,
	},
	{
		route: '/personal-training',
		Component: PersonalTrainingList,
	},
	{
		route: '/sms-pricing',
		Component: SMSPricing,
	},
	{
		route: '/send-single-sms',
		Component: SendSingleSMS,
	},
	{
		route: '/send-bulk-sms',
		Component: SendBulkSMS,
	},
	{
		route: '/projects',
		Component: Projects,
	},
	{
		route: '/tickets',
		Component: TicketList,
	},
	{
		route: '/students',
		Component: StudentsList,
	},
	{
		route: '/customers',
		Component: CustomerList,
	},
	{
		route: '/role-management',
		Component: RoleManagement,
	},
	{
		route: '/gym',
		Component: GymList,
	},
	{
		route: '/active-customers',
		Component: CustomerList,
	},
	{
		route: '/inactive-customers',
		Component: CustomerList,
	},
	{
		route: '/coaches',
		Component: CoachList,
	},
	{
		route: '/payments',
		Component: PaymentList,
	},
	{
		route: '/outstanding',
		Component: OutstandingList,
	},
	{
		route: '/products',
		Component: ProductList,
	},
	{
		route: '/memberships',
		Component: MembershipList,
	},
	{
		route: '/services',
		Component: ServiceList,
	},
	{
		route: '/users',
		Component: UserList,
	},
	{
		route: '/items',
		Component: Items,
	},
	{
		route: '/masters',
		Component: Master,
	},
	{
		route: '/invoice',
		Component: InvoiceList,
	},
	{
		route: '/voucher',
		Component: VoucherList,
	},
	{
		route: '/board',
		Component: Board,
	},
	{
		route: '/tickets',
		Component: TicketList,
	},
	{
		route: '/profile',
		Component: Profile,
	},
	{
		route: '/reports',
		Component: ReportsTab,
	},
	{
		route: '/visitors',
		Component: VisitorList,
	},
	{
		route: '/received-entry',
		Component: LoadIn,
	},
	{
		route: '/',
		Component: Dashboard,
	},
];

const SUPER_ADMIN_ROUTES = [
	{
		route: '/gyms',
		Component: GymList,
	},
	{
		route: '/gym-payments',
		Component: GymPaymentList,
	},
	{
		route: '/',
		Component: Dashboard,
	},
];


const SUPER_ADMIN_EMAILS = [
	'sathishcse1975@gmail.com',
	// "haripragaz007@gmail.com"
]

export { ACTIONS, MENUS, ROUTES, API_STATUS, SUPER_ADMIN_MENUS, SUPER_ADMIN_ROUTES, SUPER_ADMIN_EMAILS };

const CUSTOMER_TYPE = ['Customer', 'Supplier', 'Contact'];
const CATEGORIES = ['Individual', 'Business'];
const USER_TYPE = ['SuperAdmin', 'Admin', 'Staff'];
const GST_TREATMENT = [
	{ label: 'Consumer', value: 'consumer' },
	{ label: 'Registered Business', value: 'registered' },
];

const GENDER = [
	{ label: 'Male', value: 'male' },
	{ label: 'Female', value: 'female' },
];
const PAYMENT_METHOD = [
	{ label: 'Cash', value: 'cash' },
	{ label: 'UPI', value: 'upi' },
];
const PLACE_OF_SUPPLY = ['Tamilnadu'];

const SIDE_MENUS = [
	// {
	// 	name: "Users",
	// 	icon: <FI.FiUsers />,
	// 	route: "/users",
	// 	key: "1",
	// },
	// {
	// 	name: 'Preferences',
	// 	icon: <FI.FiSettings />,
	// 	route: '/preferences',
	// 	key: '3',
	// },
	// {
	// 	name: 'Settlings',
	// 	icon: <FI.FiSettings />,
	// 	route: '/settings',
	// 	key: '2',
	// },
	// {
	// 	name: 'Masters',
	// 	icon: <FI.FiSettings />,
	// 	route: '/projects',
	// 	key: '8',
	// },
];

export { CUSTOMER_TYPE, GST_TREATMENT, PLACE_OF_SUPPLY, USER_TYPE, CATEGORIES, GENDER, PAYMENT_METHOD, SIDE_MENUS };

export const NOTIFICATION_STATUS_TYPES = {
	SUCCESS: 'SUCCESS',
	INFO: 'INFO',
	ERROR: 'ERROR',
	WARNING: 'WARNING',
};

export const INTERESTED_LEVEL = [
	{
		label: 'Very Interested',
		value: 'very',
	},
	{
		label: 'Average Interested',
		value: 'average',
	},
	{
		label: 'Not Sure',
		value: 'notSure',
	},
	{
		label: 'Not Interested',
		value: 'no',
	},
];

export const PAYMENT_TYPE = {
	membership: 'membership',
	product: 'product',
	personalTraining: 'personalTraining'
}