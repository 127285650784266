import React, { useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import { Row, Col, Input, Button, Form, Drawer, Radio, Select, InputNumber } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { API_STATUS, DATE_FORMAT, PAYMENT_METHOD } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { formQueryStringFromObject } from 'helpers';
import { getApi } from 'redux/sagas/getApiDataSaga';
import DatePicker from 'components/date-picker';
import { putApi } from 'redux/sagas/putApiSaga';
import API_ENDPOINTS from 'redux/endpoints';
import { GET_EDIT_PAYMENT } from 'redux/reducers/constants';
import PageLoader from 'components/page-loader';

const EditMembershipPayment = ({ paymentAddModal, width = '40%', editPaymentId, setPaymentAddModal, refreshList, handleClose, paymentFor }) => {
	const [form] = Form.useForm();
	const globalRedux = useSelector((state) => state.globalRedux);
	const memberships = useSelector((state) => state.membershipRedux?.memberships || []);
	const services = useSelector((state) => state?.serviceRedux?.services || []);
	const coaches = useSelector((state) => state.coachRedux?.coaches);
	const editPayment = useSelector((state) => state.paymentRedux?.editPayment);
	const paymentMethodValue = Form.useWatch('paymentMethod', form);
	const serviceIdValue = Form.useWatch('serviceId', form);
	const membershipIdValue = Form.useWatch('membershipId', form);
	const paidOnValue = Form.useWatch('paidOn', form);
	const amountValue = Form.useWatch('amount', form);

	const dispatch = useDispatch();

	const branchId = globalRedux?.selectedBranch?.id;

	const string = useMemo(() => {
		const string = formQueryStringFromObject({
			branchId,
		});
		return string;
	}, [branchId]);

	const getMemberships = useCallback(
		(serviceId) => {
			const query = formQueryStringFromObject({
				serviceId,
			});
			let membershipUrl = `${SERVER_IP}api/memberships?${string}&${query}`;
			dispatch(getApi('GET_MEMBERSHIPS', membershipUrl));
		},
		[dispatch, string]
	);

	const getServices = useCallback(() => {
		let serviceUrl = `${SERVER_IP}api/services?${string}`;
		dispatch(getApi('GET_SERVICES', serviceUrl));
	}, [dispatch, string]);

	const getCoaches = useCallback(() => {
		let coachUrl = `${SERVER_IP}api/coaches?${string}`;
		dispatch(getApi('GET_COACHES', coachUrl));
	}, [dispatch, string]);

	useEffect(() => {
		if (paymentAddModal && paymentFor === 'membership') {
			getServices();
		} else form?.resetFields();
	}, [getServices, paymentAddModal, form, paymentFor]);

	useEffect(() => {
		getCoaches();
	}, [getCoaches]);

	useEffect(() => {
		if (serviceIdValue) {
			if (!editPaymentId?.membershipId) {
				form.setFieldsValue({
					membershipId: '',
					amount: '',
				});
			}
			getMemberships(serviceIdValue);
		}
	}, [serviceIdValue, getMemberships, form, editPaymentId?.membershipId]);

	const calculateBalance = useCallback(() => {
		const amountFormValue = form.getFieldValue('amount');

		let actualAmount = 0;

		const membership = memberships?.find((membership) => membership?.id === membershipIdValue);

		actualAmount = membership?.offerPrice || membership?.originalPrice;

		const balance = actualAmount - amountFormValue;
		form.setFieldValue('balance', balance);
	}, [form, membershipIdValue, memberships]);

	useEffect(() => {
		if (amountValue) {
			calculateBalance();
		}
	}, [amountValue, calculateBalance]);

	useEffect(() => {
		if (membershipIdValue) {
			const amountFormValue = form.getFieldValue('amount');
			const membership = memberships?.find((membership) => membership?.id === membershipIdValue);
			const amount = amountFormValue ? amountFormValue : membership?.offerPrice || membership?.originalPrice;
			form.setFieldsValue({
				amount,
				...(paidOnValue && { nextBillDate: moment(paidOnValue).add(membership?.days, 'days') }),
			});
			calculateBalance();
		}
	}, [membershipIdValue, form, memberships, paidOnValue, calculateBalance]);

	useEffect(() => {
		form?.resetFields();
		form?.setFieldsValue({
			paymentFor,
		});
	}, [form, paymentFor]);

	const handleSubmit = (values) => {
		let data = {
			amount: values?.amount || 0,
			balance: values?.balance || 0,
			branchId,
			customerId: editPayment?.customerId || '',
			groupId: editPayment?.groupId || '',
			id: editPaymentId,
			membershipId: values?.membershipId || '',
			nextBillDate: values?.nextBillDate || moment(),
			paidOn: values?.paidOn || '',
			paymentFor: 'membership',
			paymentMethod: values?.paymentMethod || '',
			receivedBy: values?.receivedBy || '',
			remarks: values?.remarks || '',
			serviceId: values?.serviceId || null,
			...(paymentMethodValue === PAYMENT_METHOD[1]?.value && { upiTransactionId: values?.upiTransactionId || '' }),

			// ...editPayment,
			// ...values,
			// id: editPaymentId,
			// amount: values?.amount || 0,
			// nextBillDate: values?.nextBillDate || moment(),
			// remarks: values?.remarks || '',
			// branchId,
			// membershipId: values?.membershipId || null,
			// serviceId: values?.serviceId || null,
			// receivedBy: values?.receivedBy || '',
			// paidOn: values?.paidOn || '',
			// paymentMethod: values?.paymentMethod || '',
			// ...(paymentMethodValue === PAYMENT_METHOD[1]?.value && { upiTransactionId: values?.upiTransactionId || '' }),
			// paymentFor: paymentFor,
			// balance: values?.balance || 0,
			// groupId: values?.groupId || null,
		};
		dispatch(putApi(data, 'ADD_PAYMENT', `${API_ENDPOINTS.ADD_PAYMENT}/${editPaymentId}`));

		// if (!editPaymentId) {
		// 	dispatch(postApi(data, 'ADD_PAYMENT'));
		// } else {
		// 	let url = `${SERVER_IP}api/customers/${editPaymentId.id}`;
		// 	dispatch(putApi(data, 'EDIT_CUSTOMER', url));
		// }
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_PAYMENT === 'SUCCESS' || globalRedux.apiStatus.EDIT_CUSTOMER === 'SUCCESS') {
			dispatch(resetApiStatus('ADD_PAYMENT'));
			// dispatch(resetApiStatus(editPaymentId ? 'EDIT_CUSTOMER' : 'ADD_PAYMENT'));
			refreshList?.(true);
			handleClose?.();
			form?.resetFields();
		}
	}, [globalRedux.apiStatus, editPaymentId, setPaymentAddModal, dispatch, refreshList, handleClose, form, paymentFor]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	useEffect(() => {
		if (editPaymentId && paymentAddModal) {
			form.resetFields();
			let url = `${SERVER_IP}api/payments/${editPaymentId}`;
			dispatch(getApi(GET_EDIT_PAYMENT, url));
			// form.setFieldsValue({
			// 	...editPaymentId,
			// 	customerName: editPaymentId?.customer?.customerName,
			// 	paidOn: moment(editPaymentId?.paidOn),
			// 	nextBillDate: moment(editPaymentId?.nextBillDate),
			// });
		}
	}, [editPaymentId, form, paymentAddModal, dispatch]);

	const loading = globalRedux.apiStatus.ADD_PAYMENT === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_CUSTOMER === API_STATUS.PENDING;
	const membershipLoading = useMemo(() => globalRedux?.apiStatus.GET_MEMBERSHIPS === API_STATUS.PENDING, [globalRedux?.apiStatus?.GET_MEMBERSHIPS]);
	const serviceLoading = useMemo(() => globalRedux?.apiStatus.GET_SERVICES === API_STATUS.PENDING, [globalRedux?.apiStatus?.GET_SERVICES]);
	const paymentLoading = useMemo(() => globalRedux?.apiStatus[GET_EDIT_PAYMENT] === API_STATUS.PENDING, [globalRedux?.apiStatus]);
	// const customerLoading = useMemo(() => globalRedux?.apiStatus.GET_CUSTOMER === API_STATUS.PENDING, [globalRedux?.apiStatus?.GET_CUSTOMER]);

	useEffect(() => {
		if (!!!paymentLoading && editPayment?.id && !!!membershipLoading && !!!serviceLoading) {
			form.setFieldsValue({
				...editPayment,
				customerName: editPayment?.customer?.customerName,
				membershipName: editPayment?.membership?.membershipName,
				serviceName: editPayment?.service?.serviceName,
				paidOn: moment(editPayment?.paidOn),
				nextBillDate: moment(editPayment?.nextBillDate),
			});
		}
	}, [editPayment, form, membershipLoading, paymentLoading, serviceLoading]);

	return (
		<Drawer
			placement="right"
			title={`${editPaymentId ? 'Edit' : 'New'} Membership Payment`}
			width={width || '40%'}
			open={paymentAddModal}
			closable
			onClose={() => setPaymentAddModal(false)}
			destroyOnClose={true}>
			{paymentLoading && <PageLoader />}
			<Row>
				<Col span={24}>
					<Row style={{ marginTop: 20 }}>
						<Form
							name="pay-now"
							className="required_in_right"
							style={{ width: '100%' }}
							colon={true}
							labelAlign="left"
							form={form}
							onFinish={handleSubmit}
							{...layer1FormCol}>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								label="Customer Name"
								name="customerName">
								<Input placeholder="Customer Name" disabled value={editPaymentId?.customerName} />
							</Form.Item>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								label="Service type"
								name="serviceId">
								<Select loading={serviceLoading} placeholder="Service type">
									{services?.map((service, index) => (
										<Select.Option key={index} value={service.id}>
											{service?.serviceName}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								label="Membership"
								name="membershipId">
								<Select loading={membershipLoading} placeholder="Membership">
									{memberships?.map((membership, index) => (
										<Select.Option key={index} value={membership.id}>
											{membership?.membershipName} - {membership?.offerPrice || membership?.originalPrice}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								label="Amount"
								name="amount"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<InputNumber placeholder="Amount" style={{ width: '100%' }} />
							</Form.Item>
							<Form.Item
								label="Balance"
								name="balance"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<InputNumber disabled placeholder="Balance" style={{ width: '100%' }} />
							</Form.Item>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								label="Paid on"
								name="paidOn">
								<DatePicker style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
							</Form.Item>
							{paymentFor === 'membership' && (
								<Form.Item
									rules={[
										{
											required: true,
											message: 'This Field is required!',
										},
									]}
									label="Next Bill Date"
									name="nextBillDate">
									<DatePicker style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
								</Form.Item>
							)}
							<Form.Item
								label="Payment Method"
								name="paymentMethod"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<Radio.Group style={{ width: '100%' }} options={PAYMENT_METHOD} optionType="button" buttonStyle="solid" />
							</Form.Item>
							{paymentMethodValue === PAYMENT_METHOD[1]?.value && (
								<Form.Item
									label="UPI TransactionId"
									name="upiTransactionId"
									rules={[
										{
											required: true,
											message: 'This Field is required!',
										},
									]}>
									<InputNumber placeholder="UPI TransactionId" style={{ width: '100%' }} />
								</Form.Item>
							)}
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								label="Received By"
								name="receivedBy">
								<Select placeholder="Received By">
									{coaches?.map((coach) => (
										<Select.Option value={coach.id}>{coach?.coachName}</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								label="Remarks"
								name="remarks"
								rules={[
									{
										required: false,
										message: 'This Field is required!',
									},
								]}>
								<Input.TextArea />
							</Form.Item>
							<Form.Item
								wrapperCol={{
									offset: 0,
									span: 24,
								}}>
								<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
									<Button danger style={{ width: '49%' }} onClick={() => setPaymentAddModal(false)}>
										Cancel
									</Button>
									<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
										Pay
									</Button>
								</Row>
							</Form.Item>
						</Form>
					</Row>
				</Col>
			</Row>
		</Drawer>
	);
};

export default EditMembershipPayment;
